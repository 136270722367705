import * as React from "react";
import Layout from "../components/layout.js";
import PageNavigation from "../components/PageNavigation";
import LatestArticles from "../components/LatestArticles";

const ArticlePageTemplate = ({ pageContext }) => {
  const {
    articles,
    pageIndex,
    pageCount,
    header,
    rootPagePath,
    eachPagePath,
  } = pageContext;
  return (
    <Layout>
      <LatestArticles
        header={header}
        latestArticles={articles}
      ></LatestArticles>
      <PageNavigation
        rootPagePath={rootPagePath}
        eachPagePath={eachPagePath}
        pageIndex={pageIndex}
        pageCount={pageCount}
      ></PageNavigation>
    </Layout>
  );
};

export default ArticlePageTemplate;
